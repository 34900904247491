import ThemeWrapper from "../../ThemeWrapper";
import UltimateScrollDark from "./UltimateScrollDark";

export default function UltimateDark({
  theme = "dark",
}: {
  theme?: "dark" | "light";
}) {
  return (
    <ThemeWrapper theme={theme}>
      <UltimateScrollDark />
    </ThemeWrapper>
  );
}
