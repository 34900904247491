/* jscpd:ignore-start */
import type { SetStateAction } from "react";
import type { Swiper } from "swiper/types";

import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import useSwiperSlides from "@goalteller/app-kit/hooks/useSwiperSlides";
import analytics from "@goalteller/app-kit/tools/analytics";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import { useWindowSize } from "react-use";

import { WHY_CHOOSE_US } from "../../data/whyChooseUs";
import { RootDiv } from "../../wrappers/div";
import BookCallButton from "../BookCallButton";
import GradientCard from "./GradientCard";
import styles from "./whyChooseUsDark.module.css";

const SLIDES_GAP = 16;
const AMINATION_DELAY = 4;
const SLIDES_PER_VIEW = {
  mobile: 1.1,
};

export default function WhyChooseUs() {
  const { width: windowWidth } = useWindowSize();
  const { currentId, setCurrentId, swiperRef } = useSwiperSlides();

  return (
    <RootDiv className={styles.rootContainer}>
      <MaxWidthContainer className={styles.root} isSection>
        <div className={styles.leftBox}>
          <h2 className={styles.heading}>
            Your Money Is <br />
            <SectionTitle
              as="span"
              className={styles.heading}
              variant="gradient"
            >
              Important
            </SectionTitle>{" "}
            To Us
          </h2>

          <p className={styles.infoText}>
            In this crowded world of investments, these are some highlights of
            why GoalTeller can be your trusted partner
          </p>

          <BookCallButton
            className={styles.bookCallBtn}
            onClick={() => {
              analytics.track("Book_a_free_session_now", {
                position: "why choose us",
                text: "Book a free session now",
              });
            }}
            variant="primary"
          >
            Book a FREE session
          </BookCallButton>
        </div>

        {windowWidth > BREAKPOINTS.md ? (
          <div className={styles.rightBox}>
            {WHY_CHOOSE_US.map((item) => (
              <GradientCard className={styles.box} key={item.title}>
                <h3 className={styles.title}>{item.title}</h3>
                <p className={styles.desc}>{item.desc}</p>
              </GradientCard>
            ))}
          </div>
        ) : (
          <WoSwiper
            autoplay={{
              delay: TIME.oneSecond * AMINATION_DELAY,
              disableOnInteraction: false,
            }}
            className={styles.swiperContainer}
            onSlideChange={(swiper: {
              activeIndex: SetStateAction<number>;
            }) => {
              setCurrentId(swiper.activeIndex);
            }}
            onSwiper={(swiper: Swiper) => (swiperRef.current = swiper)}
            pagination
            simulateTouch
            slidesPerView={SLIDES_PER_VIEW.mobile}
            spaceBetween={SLIDES_GAP}
          >
            {WHY_CHOOSE_US.map((item, index) => (
              <GradientCard
                className={styles.box}
                isHighlighted={currentId === index}
                key={item.title}
              >
                <div className={styles.title}>{item.title}</div>
                <div className={styles.desc}>{item.desc}</div>
              </GradientCard>
            ))}
          </WoSwiper>
        )}
      </MaxWidthContainer>
    </RootDiv>
  );
}
/* jscpd:ignore-end */
