import type { GoogleCalendarModalProps } from "@goalteller/app-kit/components/GoogleCalendar/GoogleCalendarModal";
import type { ButtonProps } from "@goalteller/app-kit/ye-design/components/atoms/Button/Button";
import type { ElementType, ReactNode } from "react";

import Button from "@goalteller/app-kit/ye-design/components/atoms/Button/Button";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useState } from "react";

import styles from "./bookCallButton.module.css";

const GoogleCalendarModal = dynamic(
  () =>
    import("@goalteller/app-kit/components/GoogleCalendar/GoogleCalendarModal"),
);

interface ContactButtonProps<TElement extends ElementType> extends ButtonProps {
  as?: TElement;
  calendarProps?: Partial<GoogleCalendarModalProps>;
  children?: ReactNode;
  className?: string;
  openSamePage?: boolean;
}

export default function BookCallButton<TElement extends ElementType>({
  as,
  calendarProps,
  children,
  onClick,
  openSamePage = true,
  ...props
}: ContactButtonProps<TElement>) {
  const [open, setOpen] = useState(false);

  const Component = as ?? Button;

  const router = useRouter();

  return (
    <>
      <Component
        className={styles.bookSessionBtn}
        onClick={(event) => {
          onClick?.(event);
          if (!openSamePage) {
            void router.push({
              pathname: "/book-call",
              query: { on: router.asPath },
            });
            return;
          }
          setOpen(true);
        }}
        variant="primary"
        {...props}
      >
        {children}
      </Component>
      {/* Conditionally render the modal to prevent styling issues when modal is closed */}
      {open && (
        <GoogleCalendarModal
          closeModal={() => {
            setOpen(false);
          }}
          isOpen={open}
          logoutOnClose
          {...calendarProps}
        />
      )}
    </>
  );
}
