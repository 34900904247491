import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { useState } from "react";

import { TESTIMONIALS } from "../data/testimonials";
import playButton from "../images/play-btn.png";
import AmpImage from "./AmpImage";
import GradientCard from "./HomePage/GradientCard";
import TestimonialsVideoPlayer from "./HomePage/Testimonials/TestimonialsVideoPlayer";
import { TestimonialDesignation } from "./HomePage/Testimonials/TestimonialsWrapper";
import ThemeWrapper from "./ThemeWrapper";
import styles from "./reviews.module.css";

const SLIDES_GAP = 16;
const SLIDES_PER_VIEW = {
  desktop: 2.2,
  mobile: 1.1,
};
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SLIDE_DELAY = TIME.oneSecond * 4;

interface ReviewCardProps {
  item: {
    content: string;
    designation?: string;
    headline: string;
    img: string;
    link?: string;
    name: string;
  };
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  setVideoLink: (link: string) => void;
}

function ReviewCard({
  item,
  playing,
  setPlaying,
  setVideoLink,
}: ReviewCardProps) {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <GradientCard className={styles.reviewCard} isHighlighted key={item.name}>
      <div className={styles.profileContainer}>
        <div className={styles.userNameContainer}>
          <div className={styles.profileImageContainer}>
            <AmpImage
              alt="profile"
              className={styles.profileImage}
              src={item.img}
            />
          </div>
          <div className={styles.userNameWrapper}>
            <h4 className={styles.userName}>{item.name}</h4>
            {!!item.designation && (
              <TestimonialDesignation className={styles.designation}>
                {item.designation}
              </TestimonialDesignation>
            )}
          </div>
        </div>
        {!!item.link && (
          <Button
            className={styles.playBtn}
            onClick={() => {
              setPlaying(!playing);
              if (item.link) {
                setVideoLink(item.link);
              }
            }}
            size="small"
            variant="primary"
          >
            <AmpImage alt="play-button" height={28} src={playButton} />
            {!isMobile && !isTablet && <>Play Video</>}
          </Button>
        )}
      </div>
      <h6>{item.headline}</h6>
      <p>{item.content}</p>
    </GradientCard>
  );
}

export default function Reviews({
  theme = "dark",
}: {
  theme?: "dark" | "light";
}) {
  const [playing, setPlaying] = useState(false);
  const [videoLink, setVideoLink] = useState(TESTIMONIALS[0].link);
  const { isMobile } = useBreakpoints();
  return (
    <ThemeWrapper theme={theme}>
      <MaxWidthContainer isSection>
        <div className={styles.wrapper}>
          <div className={styles.headingContainer}>
            <h2 className={styles.heading}>
              <SectionTitle
                as="h2"
                className={styles.heading}
                variant="gradient"
              >
                Our Reviews Speak{" "}
              </SectionTitle>
              for Themselves
            </h2>
          </div>
          <WoSwiper
            autoplay={{
              delay: SLIDE_DELAY,
              disableOnInteraction: false,
            }}
            className={styles.swiperContainer}
            simulateTouch
            slidesPerView={SLIDES_PER_VIEW[isMobile ? "mobile" : "desktop"]}
            spaceBetween={SLIDES_GAP}
            speed={1000}
          >
            {TESTIMONIALS.slice(0, TESTIMONIALS.length / 2).map((item) => {
              return (
                <ReviewCard
                  item={item}
                  key={item.name}
                  playing={playing}
                  setPlaying={setPlaying}
                  setVideoLink={setVideoLink}
                />
              );
            })}
          </WoSwiper>
          <WoSwiper
            autoplay={{
              delay: SLIDE_DELAY,
              disableOnInteraction: false,
            }}
            className={styles.swiperContainer}
            initialSlide={TESTIMONIALS.length - 1}
            simulateTouch
            slidesPerView={SLIDES_PER_VIEW[isMobile ? "mobile" : "desktop"]}
            spaceBetween={SLIDES_GAP}
          >
            {TESTIMONIALS.slice(
              TESTIMONIALS.length / 2,
              TESTIMONIALS.length,
            ).map((item) => {
              return (
                <ReviewCard
                  item={item}
                  key={item.name}
                  playing={playing}
                  setPlaying={setPlaying}
                  setVideoLink={setVideoLink}
                />
              );
            })}
          </WoSwiper>

          <div />
        </div>
        {playing && (
          <TestimonialsVideoPlayer
            playing={playing}
            setPlaying={setPlaying}
            videoLink={videoLink}
          />
        )}
      </MaxWidthContainer>
    </ThemeWrapper>
  );
}
