/* jscpd:ignore-start */

import type { SetStateAction } from "react";
import type { Swiper } from "swiper/types";

import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import useSwiperSlides from "@goalteller/app-kit/hooks/useSwiperSlides";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";

import GirlImage from "../../../images/girl-with-phone.webp";
import AmpImage from "../../AmpImage";
import ImageBg from "../ImageBg";
import { actions } from "./data";
import styles from "./ultimateScrollDark.module.css";
/* jscpd:ignore-end */

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SLIDE_DELAY = TIME.oneSecond * 4;

export default function UltimateScrollDark() {
  const { currentId, handleIndicatorClick, setCurrentId, swiperRef } =
    useSwiperSlides();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <MaxWidthContainer className={styles.topContainer} isSection>
          <div className={clsx(styles.container, styles.leftContainer)}>
            <AmpImage
              alt="Girl with phone"
              className={styles.girlImage}
              loading="eager"
              src={GirlImage}
            />
            <ImageBg />
          </div>
          <div />
          <div className={clsx(styles.container, styles.rightContainer)}>
            <p className={styles.description}>
              A Sneak Peek into Our App, Where Technology Meets Expert Financial
              Advice.
            </p>

            <Button
              className={styles.knowMoreBtn}
              onClick={() => {
                window.open(
                  `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/login`,
                  "_blank",
                );
              }}
              theme="dark"
            >
              Know More
            </Button>
          </div>
        </MaxWidthContainer>
      </div>
      <MaxWidthContainer>
        <div className={styles.slideTitleContainer}>
          {actions.map((item, index) => (
            <button
              className={styles.slideTitleButton}
              key={index}
              onClick={() => {
                handleIndicatorClick(index);
              }}
            >
              <SectionTitle
                as="h3"
                // eslint-disable-next-line css-modules/no-undef-class
                className={`${styles.slideTitle} ${currentId === index ? styles.active : ""}`}
                size="small"
                variant={currentId === index ? "gradient" : undefined}
              >
                {item.title}
              </SectionTitle>
            </button>
          ))}
        </div>
        <div className={styles.slider}>
          <WoSwiper
            autoplay={{
              delay: SLIDE_DELAY,
              disableOnInteraction: true,
            }}
            direction="horizontal"
            freeMode={{ enabled: false }}
            mousewheel={{ enabled: false }}
            navigation={false}
            onSlideChange={(swiper: {
              activeIndex: SetStateAction<number>;
            }) => {
              setCurrentId(swiper.activeIndex);
            }}
            onSwiper={(swiper: Swiper) => (swiperRef.current = swiper)}
            slidesPerView={1}
          >
            {actions.map((item, index) => {
              return (
                <div className={styles.card} key={index}>
                  <div className={styles.imageContainer}>
                    <AmpImage
                      alt="what makes goalteller ultimate depicted on mobile device"
                      className={styles.image}
                      src={item.imageURL}
                      width={242}
                    />
                  </div>
                </div>
              );
            })}
          </WoSwiper>
        </div>
      </MaxWidthContainer>
    </div>
  );
}
