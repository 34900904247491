import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

import { INVESTOR_ITEMS } from "../../data/investors";
import { BoxTitleDiv, RootDiv } from "../../wrappers/div";
import AmpImage from "../AmpImage";
import GradientCard from "./GradientCard";
import styles from "./landingInvestorDark.module.css";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SWITCH_INTERVAL = TIME.oneSecond * 3;

export default function LandingInvestor() {
  const [investors, setInvestors] = useState(INVESTOR_ITEMS);
  const userInteractionRef = useRef(false);

  const handleSpotlight = (index: number) => {
    const next = [...INVESTOR_ITEMS];
    const temp = next[index];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    next[index] = next.at(-1)!;
    next[next.length - 1] = temp;
    setInvestors(next);
  };

  useEffectOnce(() => {
    let loopIndex = 0;
    const interval = setInterval(() => {
      if (userInteractionRef.current) {
        clearInterval(interval);
        return;
      }
      handleSpotlight(loopIndex);
      loopIndex = (loopIndex + 1) % investors.length;
    }, SWITCH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <RootDiv>
      <MaxWidthContainer className={styles.wrapper} isSection>
        <SectionTitle as="h2" className={styles.title} size="small">
          Backed by some of India’s finest and biggest
          <SectionTitle
            as="span"
            className={styles.title}
            size="small"
            variant="gradient"
          >
            {" "}
            entrepreneurs
          </SectionTitle>
        </SectionTitle>
        <GradientCard className={styles.container} isHighlighted>
          {investors.map((element, index) => (
            <div className={styles.footer} key={element.name}>
              <Button
                className={clsx(
                  styles.investorButton,
                  index === investors.length - 1 &&
                    styles.inveserButtonNoEffect,
                )}
                onClick={() => {
                  userInteractionRef.current = true;
                  handleSpotlight(index);
                }}
                variant="borderless"
              >
                <AmpImage
                  alt="investors image"
                  className={styles.investor}
                  src={element.image}
                />
              </Button>
              {index === investors.length - 1 && (
                <>
                  {" "}
                  <BoxTitleDiv className={styles.footerTitle}>
                    <SectionTitle as="h3" size="tiny" variant="gradient">
                      {element.name}
                    </SectionTitle>
                  </BoxTitleDiv>
                  <div className={styles.footerDesignation}>
                    {element.designation}
                  </div>
                </>
              )}
            </div>
          ))}
        </GradientCard>
      </MaxWidthContainer>
    </RootDiv>
  );
}
