import type { ReactNode } from "react";

import clsx from "clsx";

import styles from "./gradientCard.module.css";
export default function GradientCard({
  children,
  className,
  isHighlighted = false,
}: {
  children: ReactNode;
  className?: string;
  isHighlighted?: boolean;
}) {
  return (
    <div
      className={clsx(
        styles.card,
        className,
        isHighlighted ? styles.highlighted : "",
      )}
    >
      {children}
    </div>
  );
}
