import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import analytics from "@goalteller/app-kit/tools/analytics";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import Link from "next/link";
import { useWindowSize } from "react-use";

import yearBook from "../../images/diwali-samvat.webp";
import personalFinance from "../../images/personal-finance.webp";
import { RootDiv } from "../../wrappers/div";
import AmpImage from "../AmpImage";
import GradientCard from "./GradientCard";
import styles from "./annualReportDark.module.css";

const bookData = [
  {
    image: yearBook,
    link: "/annual-book",
    subtitle:
      "As we come to the close to 2024, we thought of sharing in this newsletter a summary of the Year Gone By and expectations from 2024.",
    title: "Annual Yearbook",
  },
  {
    image: personalFinance,
    link: "/coffee-book",
    subtitle:
      "An exclusive compilation of personal strategies, guru portfolios and investment strategies to help you gain valuable insights on investing ",
    title: "Coffee Table Book",
  },
];

function AnnualReportCard({
  image,
  link,
  subtitle,
  title,
}: {
  image: string;
  link: string;
  subtitle: string;
  title: string;
}) {
  return (
    <GradientCard className={styles.booksContainer} isHighlighted>
      <h3 className={styles.title}>{title}</h3>

      <div className={styles.container}>
        <p className={styles.subTitle}>{subtitle}</p>
        <div className={styles.imageWrapper}>
          <AmpImage alt="year book" className={styles.img} src={image} />
        </div>
      </div>

      <Link
        className={styles.link}
        href={link}
        onClick={() => {
          analytics.track("Button", {
            position: "download reports",
            text: "Download Now",
          });
        }}
      >
        <Button theme="dark">Download Now</Button>
      </Link>
    </GradientCard>
  );
}
const AUTOPLAY_DELAY = 4000;

export default function AnnualReport() {
  const { width: windowWidth } = useWindowSize();
  return (
    <RootDiv>
      <MaxWidthContainer className={styles.swiperContainer} isSection>
        <div className={styles.headingContainer}>
          <SectionTitle as="h2" className={styles.heading}>
            Download{" "}
            <SectionTitle
              as="span"
              className={styles.heading}
              variant="gradient"
            >
              Exclusive <br />
              Insightful
            </SectionTitle>{" "}
            Handbooks
          </SectionTitle>
          <p className={styles.infoText}>
            Check our exclusive research publications.
          </p>
        </div>
        {windowWidth > BREAKPOINTS.md ? (
          <div className={styles.reportContainer}>
            {bookData.map((item, index) => (
              <AnnualReportCard
                image={item.image}
                key={index}
                link={item.link}
                subtitle={item.subtitle}
                title={item.title}
              />
            ))}
          </div>
        ) : (
          <WoSwiper
            autoplay={{
              delay: AUTOPLAY_DELAY,
              disableOnInteraction: true,
            }}
            pagination
            simulateTouch
            slidesPerView={1.1}
            spaceBetween={16}
          >
            {bookData.map((item, index) => (
              <AnnualReportCard
                image={item.image}
                key={index}
                link={item.link}
                subtitle={item.subtitle}
                title={item.title}
              />
            ))}
          </WoSwiper>
        )}
      </MaxWidthContainer>
    </RootDiv>
  );
}
