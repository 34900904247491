import ErrorMessageTag from "@goalteller/app-kit/components/Errors/ErrorMessageTag";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import generateCssWrapper from "@goalteller/app-kit/ye-design/wrappers/generateCssWrapper";

import styles from "./div.module.css";

export const CenterContainerDiv = generateCssWrapper(styles.centerContainer);
export const SwiperContainerDiv = generateCssWrapper(styles.swiperContainer);
export const InfoButtonContainerDiv = generateCssWrapper(styles.infoBtn);
export const CenterContainerButton = generateCssWrapper(
  styles.centerContainer,
  Button,
);
export const PrimaryTag = generateCssWrapper(styles.primaryTag);

export const CategoryWrapper = generateCssWrapper(styles.category);

export const PrimaryWrapper = generateCssWrapper(styles.primaryWrapper);

export const ReportHeaderDiv = generateCssWrapper(styles.header);

export const InfoContainerDiv = generateCssWrapper(styles.infoContainer);

export const BoxTitleDiv = generateCssWrapper(styles.title);

export const RootDiv = generateCssWrapper(styles.root);

export const ErrorMessageTagWrapper = generateCssWrapper(
  styles.error,
  ErrorMessageTag,
);
